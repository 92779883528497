<template>
  <Slider ref="slider" :info="contentList" :option="options" class="list" coach-tv-playlist preventTouchMove>
    <template v-slot:slide="{ info }">
      <CoachTvPlaylistCard
          :dateTime="info.contentCreatedDatetime"
          :thumbnailUrl="info.thumbnailUrl"
          :title="info.title"
          @click="$emit('click', info)"
          :theme="theme"
      />
    </template>
  </Slider>
</template>

<script>
import CoachTvPlaylistCard from '@/views/components/coaching/coachTv/CoachTvPlaylistCard.vue';
import Slider from "@shared/components/common/Slider.vue";

export default {
  name: "CoachTvPlaylist",
  components: {
    Slider,
    CoachTvPlaylistCard,
  },
  props: {
    playlistType: {
      type: String,
      default: '',
    },
    /** @param {CoachTvContentList} contentList */
    contentList: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'base'
    }
  },
  computed: {
    options() {
      const breakpoints = {};
      const spaceBetween = 16;
      breakpoints[1280] = {
        slidesPerView: this.playlistType === 'main' ? 4 : 3,
        slidesPerGroup: 3,
      };
      breakpoints[768] = {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      };
      breakpoints[0] = {
        slidesPerView: 1,
        slidesPerGroup: 1
      };
      return {breakpoints, spaceBetween};
    },
  },
  methods: {
    next() {
      this.$nextTick(() => {
        const {slider} = this.$refs
        if (!slider) return;
        this.$emit('next');
        slider.next();
      })
    },
    prev() {
      this.$nextTick(() => {
        const {slider} = this.$refs
        if (!slider) return;
        this.$emit('prev');
        slider.prev();
      })
    },
  }
}
</script>

<style lang="less">
[coach-tv-playlist] {
}
</style>
