<template>
  <div coach-detail-activity>
    <div class="edit-info-holder" v-if="isEditable">
      <CoachInfoBanner>코치님 활동 내역은 편집할 수 없습니다.</CoachInfoBanner>
    </div>
    <section v-if="showCoachTvSection || isEditable" class="coach-tv">
      <div class="top">
        <Heading>
          <template #title>코치님 활동 영상</template>
          <template #sub>({{ _coachTvTotalCount }})</template>
        </Heading>
        <div class="btn-holder" v-show="showCoachTvSection">
          <button :class="{disable: prevBtnDisable}" class="arrow-btn left"
            @click="prevBtnHandler">
            <img
              class="btn-icon left"
              src="/img/icon/icon-arrow-down-dark.svg" />
          </button>
          <button :class="{disable: nextBtnDisable}" class="arrow-btn right"
            @click="nextBtnHandler">
            <img
              class="btn-icon right"
              src="/img/icon/icon-arrow-down-dark.svg" />
          </button>
        </div>
      </div>
      <CoachTvPlaylist v-if="showCoachTvSection" ref="playlist" :slidesPerView="slidesPerView" :contentList="coachTvItems" @click="coachTvHandler" theme="coach-detail" />
      <div class="no-coach-tv-edit" v-if="!showCoachTvSection && isEditable">
        <h4>코치님 활동 영상이 없습니다.</h4>
        <p>LoL 코칭과 관련된 유튜브 영상이 있다면<br>‘코치님 활동 영상’ 등록을 요청해보세요!</p>
        <BasicButton @click="coachTvEditBtnHandler">관리자에게 문의하기</BasicButton>
      </div>
    </section>
    <section v-if="showAnswerSection || isEditable" ref="answerSection" class="coach-answer">
      <div v-show="showAnswerSection" ref="answerBg" class="section-bg" />
      <div class="top">
        <Heading>
          <template #title>코치님의 답변</template>
          <template #sub>({{ _answerTotalCount }})</template>
        </Heading>
      </div>
      <div v-if="showAnswerSection" class="answer-list">
        <QuestionPreview class="answer-item" v-for="item in answerItems" :question="item" :key="item.boardWriteId" clickable @examine="answerHandler" />
        <Pagination :chunkSize="5" :info="answerInfo" :showSize="isMobile ? 5 : 10" class="pagination" theme="coaching" @change="paginationHandler" />
      </div>
      <div class="no-coach-answer-edit" v-if="!showAnswerSection && isEditable">
        <h4>아직 작성한 답변이 없습니다.</h4>
        <p>코치홈 > QnA로 이동하여<br>수강생의 궁금증을 해결해주세요!</p>
        <BasicButton @click="answerEditBtnHandler">코치홈으로 이동</BasicButton>
      </div>
    </section>
    <div v-if="!showCoachTvSection && !showAnswerSection && !isEditable" class="no-activity">
      <img src="/img/coaching/emoji-eyes.svg" alt="no-content-icon" />
      <p>아직 코치님이 작성한 답변이 없습니다.</p>
    </div>
  </div>
</template>

<script>
import { commaDecimal } from 'shared/utils/numberUtils';
import _debounce from 'lodash/debounce';
import { state } from '@shared/utils/storeUtils';
import _get from 'lodash/get';
import Heading from '@/views/components/common/text/Heading.vue';
import QuestionPreview from '@/views/components/coaching/question/QuestionPreview.vue';
import CoachTvPlaylist from '@/views/components/coaching/coachTv/CoachTvPlaylist.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import MovModal from '@/views/components/common/modal/MovModal.vue';
import CoachInfoBanner from '@/views/components/coaching/CoachInfoBanner.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';

export default {
  name: 'CoachDetailActivity',
  components: { BasicButton, CoachInfoBanner, Heading, CoachTvPlaylist, QuestionPreview, Pagination },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    coachTvItems: [],
    coachTvTotalCount: 0,
    coachTvSlideIndex: 0,
    coachTvCursor: 0,
    answerInfo: {},
  }),
  watch: {
    matchedMedia() {
      this.$nextTick(() => {
        if (this.coachTvTotalCount) {
          const slider = _get(this, '$refs.playlist.$refs.slider');
          this.slideIndex = slider?.now;
        }
      });
    },
  },
  computed: {
    matchedMedia: state('browser', 'matchedMedia'),
    isMobile() {
      return this.matchedMedia?.charAt(0) === 'M';
    },
    isDesktop() {
      return this.matchedMedia?.charAt(0) === 'D';
    },
    showAnswerSection() {
      return !!this.answerTotalCount;
    },
    showCoachTvSection() {
      return !!this.coachTvTotalCount;
    },
    _coachTvTotalCount() {
      return commaDecimal(this.coachTvTotalCount);
    },
    maxCoachTvSlideIndex() {
      const { coachTvTotalCount } = this;
      if (coachTvTotalCount <= 0) return 0;
      if (this.isMobile) return coachTvTotalCount - 1;
      return Math.ceil(coachTvTotalCount / 3) - 1;
    },
    prevBtnDisable() {
      return this.coachTvSlideIndex <= 0;
    },
    nextBtnDisable() {
      return this.coachTvSlideIndex >= this.maxCoachTvSlideIndex;
    },
    slidesPerView() {
      if (this.isDesktop) {
        return 3;
      } return 1;
    },
    answerTotalCount() {
      return this.answerInfo?.totalCount || 0;
    },
    _answerTotalCount() {
      return commaDecimal(this.answerTotalCount);
    },
    answerItems() {
      return this.answerInfo?.items;
    },
  },
  methods: {
    coachTvEditBtnHandler() {
      window.Beacon('open');
    },
    answerEditBtnHandler() {
      this.$router.push({ name: 'TutorHome', query: { 'scroll': 'qna' } });
    },
    coachTvHandler(info) {
      const contentUrl = info?.contentUrl;
      this.$modal(MovModal, { mov: contentUrl });
    },
    answerHandler(boardWriteId) {
      const coachId = this.$route.params?.coachId;
      this.$router.push({ name: 'CoachQuestion', params: { gameId: 'lol', boardWriteId }, query: { coachId } });
    },
    prevBtnHandler() {
      this.coachTvSlideIndex -= 1;
      const { playlist } = this.$refs;
      if (!playlist) return;
      playlist?.prev();
    },
    nextBtnHandler: _debounce(async function () {
        await this.getCoachTvList();
        this.coachTvSlideIndex += 1;
        this.$nextTick(() => {
          const { playlist } = this.$refs;
          playlist?.next();
        });
      }, 200, { trailing: true }),
    async getCoachTvList() {
      const coachId = this.$route.params?.coachId;
      const result = await this.$services.coaching.getCoachTvContentList({ order: 'contentCreatedDatetime desc', coachId, cursor: this.coachTvCursor });
      const { items, nextCursor } = result;
      this.coachTvItems = [...this.coachTvItems, ...items];
      this.coachTvCursor = nextCursor;
    },
    async getQuestions(cursor) {
      const coachId = this.$route.params?.coachId;
      this.answerInfo = await this.$services.coaching.getQuestions({ orderBy: 'createdDatetime', q: `coachId eq ${coachId}`, cursor, size: 5 });
    },
    answerSectionBgPos() {
      if (!this.showAnswerSection) return;
      const bg = this.$refs.answerBg;
      const section = this.$refs.answerSection;
      const rect = section?.getBoundingClientRect();
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
      bg.style.left = `${`-${rect?.x}` - scrollBarWidth}px`;
    },
    paginationHandler(cursor) {
      this.getQuestions(cursor);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.answerSectionBgPos();
    });
    window.addEventListener('resize', this.answerSectionBgPos);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.answerSectionBgPos);
  },
  async asyncData({ route, services }) {
    const coachId = route.params?.coachId;
    const coachTvResult = await services.coaching.getCoachTvContentList({ order: 'contentCreatedDatetime desc', coachId });
    const coachTvItems = coachTvResult?.items;
    const coachTvTotalCount = coachTvResult?.totalCount;
    const coachTvCursor = coachTvResult?.nextCursor;
    const answerInfo = await services.coaching.getQuestions({ orderBy: 'createdDatetime', q: `coachId eq ${coachId}` });
    return { coachTvItems, coachTvCursor, coachTvTotalCount, answerInfo };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-detail-activity] {
  .edit-info-holder { .mt(32);}
  .coach-tv { .pt(32); .pb(34);
    > .top {.mb(24); .flex; .space-between; .items-center;
      [heading] {.h(fit-content); }
      .btn-holder { .flex; .justify-center; .items-center; .bgc(unset); .w(unset); .mb(0);
        .arrow-btn {.w(40);.h(40px);.p(5);.br(12);.bgc(#ebebf0); border: unset; .mb(0);
          .btn-icon {.w(100%);.h(100%);.o(0.4);border: unset;border: 0;
            &.left {transform: rotate(90deg);}
            &.right {transform: rotate(270deg);}
          }
          &.disable { .bgc(#d5d5de); pointer-events: none; }
          &.right { .ml(6px);}
        }
      }
    }
    .no-coach-tv-edit {.flex; flex-direction: column; justify-content: center; .items-center; .mt(64); .h(118);
      > h4 {.fs(18); .bold; .c(#000); .mb(8);}
      > p {.fs(14); .c(#191919); .lh(20); .mb(12); text-align: center;}
      > [basic-button] {.w(127); .h(32); .fs(12); .p(0);}
    }
  }
  .coach-answer { .pt(30); .pb(34); .rel;
    > .top { .rel; .mb(24); .z(1);
      [heading] {}
    }
    .section-bg { .abs; .w(100vw); .h(100%); .bgc(#f6f6f6); .z(0); .t(0); .l(0);}
    .answer-list { .rel; .z(1); .flex; flex-direction: column; gap: 12px;
    }

    .no-coach-answer-edit {.flex; flex-direction: column; justify-content: center; .items-center; .mt(64); .h(118);
      > h4 {.fs(18); .bold; .c(#000); .mb(8);}
      > p {.fs(14); .c(#191919); .lh(20); .mb(12); text-align: center;}
      > [basic-button] {.w(140); .h(32); .fs(12); .p(0);}
    }
  }

  .no-activity {.w(100%); .h(370); .flex; .justify-center; .items-center; flex-direction: column;
    > img {.mb(16);}
    > p {.fs(14); .c(#000); .o(0.8);
    }
  }

  .pagination {.mt(15);
    a {.ml(4); .mr(4);}
  }

  @media (@tp-down) {
    .coach-tv {
      > .top {
        .btn-holder { .mb(0);
          .arrow-btn {.wh(32); .br(12);}
        }
      }
    }
  }
}
</style>
